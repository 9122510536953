import { UcoastEl } from '@/scripts/core/UcoastEl'
import { qsOptional, qsRequired, toggleIsEmpty, toggleLoading } from '@/scripts/functions'
import { addToCartConfig, routes } from '@/scripts/setup'

import { type CartDrawer } from '@/scripts/cart/cart-drawer'

export class PairWith extends UcoastEl {
	submitButton: HTMLButtonElement
	form: HTMLFormElement
	cart: CartDrawer
	static htmlSelector = 'pair-with'
	constructor() {
		super()
		this.form = this.initForm()
		this.submitButton = qsRequired('button', this)
		if (document.querySelector('cart-drawer') && this.submitButton)
			this.submitButton.setAttribute('aria-haspopup', 'dialog')
		this.cart = qsOptional('cart-notification') || qsRequired('cart-drawer')
		this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
	}

	initForm(): HTMLFormElement {
		const form = qsRequired<HTMLFormElement>('form', this)
		const id = qsRequired<HTMLInputElement>('[name=id]', form)
		id.disabled = false
		form.addEventListener('submit', this.onSubmitHandler.bind(this))
		return form
	}

	onSubmitHandler(evt: SubmitEvent): void {
		evt.preventDefault()

		this.submitButton.setAttribute('aria-disabled', '')
		toggleLoading(this.submitButton, true)

		const formData = new FormData(this.form)

		if (this.cart) {
			formData.append(
				'sections',
				this.cart
					.getSectionsToRender()
					.map((section) => section.id)
					.join(',')
			)
			formData.append('sections_url', window.location.pathname)
			const documentActiveElement = document.activeElement as HTMLElement | undefined
			if (documentActiveElement) {
				this.cart.setActiveElement(documentActiveElement)
			}
		} else {
			console.log('aint no cart baby')
		}
		const config = addToCartConfig(formData)

		fetch(`${routes.cart_add_url}`, config)
			.then((response) => {
				return response
			})
			.then((response) => response.json())
			.then((response) => {
				if (response.status) {
					console.error(response.status)
					return
				} else if (!this.cart) {
					window.location.href = routes.cart_url
					return
				}
				this.cart.renderContents(response)
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				toggleLoading(this.submitButton, false)
				if (this.cart && this.cart.hasAttribute('data-uc-is-empty'))
					toggleIsEmpty(this.cart, false)
				this.cart.open()
				this.submitButton.removeAttribute('aria-disabled')
			})
	}
}
