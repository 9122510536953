import { getBackendRoute, qsOptional, qsRequired } from '../functions'
import { addToKlaviyoListConfig, notifyMeConfig } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { type KlaviyoPopup, NotifyMe } from '@/scripts/content/modal'

export class KlaviyoForm extends UcoastEl {
	static htmlSelector = 'klaviyo-form'
	form: HTMLFormElement
	submitButton: HTMLButtonElement
	emailInput: HTMLInputElement
	successMessage: string
	route: string
	popup?: KlaviyoPopup
	constructor() {
		super()
		this.form = qsRequired('form', this)
		this.submitButton = qsRequired('[type="submit"]', this)
		this.emailInput = qsRequired('[type="email"]', this)
		this.successMessage = this.getSuccessMessage()
		this.route = `${getBackendRoute()}/api/klaviyo-list`
		console.log('route', this.route)
		this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
		this.popup = qsOptional<KlaviyoPopup>('klaviyo-popup', this)
	}

	getSuccessMessage(): string {
		const message = this.form.getAttribute('data-success')
		if (!message) throw new Error('Success message not found on form')
		return message
	}

	onSubmitHandler(event: Event) {
		event.preventDefault()
		console.log('submit form')
		if (this.submitButton.getAttribute('aria-disabled') === 'true') return
		if (this.emailInput.value.length < 1) return

		this.submitButton.setAttribute('aria-disabled', 'true')
		this.submitButton.classList.add('loading')

		const formData = new FormData(this.form)

		const config = addToKlaviyoListConfig(formData)
		console.log(this.route, config.body)

		fetch(this.route, config)
			.then((response) => response.json())
			.then((response) => {
				this.emailInput.value = ''
				if (response.success) {
					this.emailInput.placeholder = this.successMessage
					if (this.popup) {
						window.setTimeout(() => {
							this.popup?.hide()
						}, 5000)
					}
				} else {
					this.emailInput.placeholder = 'This email address is invalid.'
				}
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				this.submitButton.classList.remove('loading')
				this.submitButton.removeAttribute('aria-disabled')
			})
	}
}

export class KlaviyoOosForm extends UcoastEl {
	static htmlSelector = 'klaviyo-oos'
	form: HTMLFormElement
	submitButton: HTMLButtonElement
	emailInput: HTMLInputElement
	successEl: HTMLElement
	route: string
	popup?: NotifyMe
	variant?: string
	constructor() {
		super()
		this.form = qsRequired('form', this)
		this.submitButton = qsRequired('[type="submit"]', this)
		this.emailInput = qsRequired('[type="email"]', this)
		this.successEl = qsRequired('[data-uc-form-status]', this)
		this.route = `${getBackendRoute()}/api/klaviyo-oos`
		this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
		this.popup = qsOptional<NotifyMe>('notify-me', this)
	}

	onSubmitHandler(event: Event) {
		event.preventDefault()
		if (this.submitButton.getAttribute('aria-disabled') === 'true') return
		if (this.emailInput.value.length < 1) return

		this.submitButton.setAttribute('aria-disabled', 'true')
		this.submitButton.classList.add('loading')

		const formData = new FormData(this.form)

		const config = notifyMeConfig(formData)

		fetch(this.route, config)
			.then((response) => response.json())
			.then((response) => {
				this.emailInput.value = ''
				if (response.success) {
					this.successEl.classList.remove('hidden')
					this.submitButton.classList.add('success')
					this.emailInput.placeholder = 'Thanks for signing up'
					if (this.popup) {
						window.setTimeout(() => {
							this.popup?.hide()
						}, 5000)
					}
				} else {
					this.emailInput.placeholder = 'This email address is invalid.'
				}
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				this.submitButton.classList.remove('loading')
				this.submitButton.removeAttribute('aria-disabled')
			})
	}
}
